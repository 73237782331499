.vehicleInfoIcons {
    margin-bottom: 10px;
    column-gap: 15px !important;
    justify-content: center;

    img {
       max-width: 17px;
       width: 100%;
       max-height: 17px;
   }

   .round-color {
        width: 17px;
        height: 17px;
        border-radius: 50%;
        border: 1px solid black;
    }
}